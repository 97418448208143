// @ts-nocheck
import MetaDefault from 'components/meta/MetaDefault';
import dynamic from 'next/dynamic';

const LoginModal = dynamic(() => import('screens/auth/LoginModal'), {
  ssr: false
});

const login = () => {
  return (
    <div className="max-w-screen flex h-screen">
      <div className='self-center min-w-[50%] '>
        <LoginModal />
      </div>

      <img
        className="hidden max-w-[50%] text-white lg:inline-block"
        src="/signupBack.jpeg"
        alt="Misfans"
        placeholder='blur'
      />
      <MetaDefault title={'Iniciar Sesión'} />
    </div>
  );
};

export default login;
